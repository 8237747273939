<template>
  <div class="parlay-desglose">
    <div class="parlay-desglose__contclose">
      <div class="parlay-desglose__contclose__item" @click="onShowClick">
        <div class="parlay-desglose__contclose__item__text">Cerrar</div>
        <div class="parlay-desglose__contclose__item__icon"><img src="/images/icn_close.png" /></div>
      </div>
    </div>
    <div class="parlay-desglose__infocont">
      <template v-if="isTips">
        <div
          class="parlay-desglose__infocont__contbuttontips"
          @click="
            clickShowFreeSection();
            sendAnalyticsStrategy();
          "
        >
          <div class="button-making-money">
            <div class="button-making-money__text">
              Estrategia Value Betting
            </div>
            <div class="button-making-money__icon"><img src="/images/icn_eye.png" /></div>
          </div>
        </div>
      </template>
      <template v-if="isTips">
        <div class="parlay-desglose__infocont__contheader">
          <div class="parlay-desglose__infocont__contheader__header" :class="isMobile ? 'margin-top: -14px' : ''">
            Desglose de cada Parlay por jornada:
          </div>
        </div>
      </template>
      <template v-else>
        <div class="parlay-desglose__infocont__contheader">
          <div class="parlay-desglose__infocont__contheader__header">Desglose de cada Parlay por jornada:</div>
        </div>
      </template>

      <div>
        <template v-for="(item, key) in getParlayFreeBreakdown">
          <template v-if="key < 6">
            <div class="item-game" :key="key">
              <div class="item-game__matchday" :data="item['matchday']">
                <template v-if="isMobile">{{ item['matchday'] }}</template>
                <template v-else>{{ item['match'] }}</template>
              </div>
              <div class="item-game__contgames">
                <div class="item-game__contgames__gameitem">
                  <div class="item-game__contgames__gameitem__team">
                    <div class="item-game__contgames__gameitem__team__shield">
                      <img :src="item['parlay'][0]['logo']" />
                    </div>
                    <div class="item-game__contgames__gameitem__team__name">{{ item['parlay'][0]['name'] }}</div>
                  </div>
                  <div class="item-game__contgames__gameitem__bet">{{ item['parlay'][0]['bet'] }}</div>
                </div>
                <div class="item-game__contgames__more">+</div>
                <div class="item-game__contgames__gameitem">
                  <div class="item-game__contgames__gameitem__team">
                    <div class="item-game__contgames__gameitem__team__shield">
                      <img :src="item['parlay'][1]['logo']" />
                    </div>
                    <div class="item-game__contgames__gameitem__team__name">{{ item['parlay'][1]['name'] }}</div>
                  </div>
                  <div class="item-game__contgames__gameitem__bet">{{ item['parlay'][1]['bet'] }}</div>
                </div>
              </div>
              <div class="item-game__betinfo">
                <div class="item-game__betinfo__title">Apostado:</div>
                <div class="item-game__betinfo__money">${{ item['parlay'][0]['investment'] }}.00 MXN</div>
              </div>
              <div class="item-game__equal">=</div>
              <div class="item-game__contgain">
                <div class="item-game__contgain__payout">
                  <span class="item-game__contgain__payout__title">Payout:</span>&nbsp;<span
                    class="item-game__contgain__payout__money"
                    >${{ item['payout'] }}</span
                  >
                </div>
                <template v-if="item['gain'] < 0">
                  <div class="item-game__contgain__money pink-color">
                    <div class="item-game__contgain__money__images"><img src="/images/pink_arrow.png" /></div>
                    {{ item['gain'] }}.<span class="item-game__contgain__money__min pink-color">{{
                      item['gaindeci']
                    }}</span>
                    <span class="item-game__contgain__money__type pink-color">&nbsp;MXN</span>
                  </div></template
                >
                <template v-else>
                  <div class="item-game__contgain__money">
                    <div class="item-game__contgain__money__images"><img src="/images/green_arrow.png" /></div>
                    +{{ item['gain'] }}.<span class="item-game__contgain__money__min">{{ item['gaindeci'] }}</span>
                    <span class="item-game__contgain__money__type">&nbsp;MXN</span>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div v-if="showgames">
        <template v-for="(item, key) in getParlayFreeBreakdown">
          <template v-if="key > 5">
            <div class="item-game" :key="key">
              <div class="item-game__matchday" :data="item['matchday']">
                <template v-if="isMobile">{{ item['matchday'] }}</template>
                <template v-else>{{ item['match'] }}</template>
              </div>
              <div class="item-game__contgames">
                <div class="item-game__contgames__gameitem">
                  <div class="item-game__contgames__gameitem__team">
                    <div class="item-game__contgames__gameitem__team__shield">
                      <img :src="item['parlay'][0]['logo']" />
                    </div>
                    <div class="item-game__contgames__gameitem__team__name">{{ item['parlay'][0]['name'] }}</div>
                  </div>
                  <div class="item-game__contgames__gameitem__bet">{{ item['parlay'][0]['bet'] }}</div>
                </div>
                <div class="item-game__contgames__more">+</div>
                <div class="item-game__contgames__gameitem">
                  <div class="item-game__contgames__gameitem__team">
                    <div class="item-game__contgames__gameitem__team__shield">
                      <img :src="item['parlay'][1]['logo']" />
                    </div>
                    <div class="item-game__contgames__gameitem__team__name">{{ item['parlay'][1]['name'] }}</div>
                  </div>
                  <div class="item-game__contgames__gameitem__bet">{{ item['parlay'][1]['bet'] }}</div>
                </div>
              </div>
              <div class="item-game__betinfo">
                <div class="item-game__betinfo__title">Apostado:</div>
                <div class="item-game__betinfo__money">${{ item['parlay'][0]['investment'] }}.00 MXN</div>
              </div>
              <div class="item-game__equal">=</div>
              <div class="item-game__contgain">
                <div class="item-game__contgain__payout">
                  <span class="item-game__contgain__payout__title">Payout:</span>&nbsp;<span
                    class="item-game__contgain__payout__money"
                    >${{ item['payout'] }}</span
                  >
                </div>
                <template v-if="item['gain'] < 0">
                  <div class="item-game__contgain__money pink-color">
                    <div class="item-game__contgain__money__images"><img src="/images/pink_arrow.png" /></div>
                    {{ item['gain'] }}.<span class="item-game__contgain__money__min pink-color">{{
                      item['gaindeci']
                    }}</span>
                    <span class="item-game__contgain__money__type pink-color">&nbsp;MXN</span>
                  </div>
                </template>
                <template v-else>
                  <div class="item-game__contgain__money">
                    <div class="item-game__contgain__money__images"><img src="/images/green_arrow.png" /></div>
                    +{{ item['gain'] }}.<span class="item-game__contgain__money__min">{{ item['gaindeci'] }}</span>
                    <span class="item-game__contgain__money__type">&nbsp;MXN</span>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div class="parlay-desglose__infocont__contshowmore">
        <div class="line-separator"></div>
        <div class="parlay-desglose__infocont__contshowmore__button">
          <div class="parlay-desglose__infocont__contshowmore__button__cont" @click="clickShowMoreGames">
            <div class="parlay-desglose__infocont__contshowmore__button__cont__title">
              <template v-if="!showgames">Ver más Jornadas</template>
              <template v-else>Ver menos Jornadas</template>
            </div>
            <div class="parlay-desglose__infocont__contshowmore__button__cont__image">
              <template v-if="!showgames"><img src="/images/icn_seplegar.png"/></template>
              <template v-else><img src="/images/icn_colapsar.png"/></template>
            </div>
          </div>
        </div>
      </div>
      <div class="parlay-desglose__infocont__conttotal">
        <div class="parlay-desglose__infocont__conttotal__total-item">
          <div class="parlay-desglose__infocont__conttotal__total-item__title">TOTAL</div>
          <div class="parlay-desglose__infocont__conttotal__total-item__money">${{ getParlayFreeGain }} MXN</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { parlayValueBetting, parlayBreakdown, parlayBreakdownSeeMore, parlayBreakdownSeeLess } from '@/utils/analytics';

export default {
  name: 'ParlayDesglose',
  components: {},
  data() {
    return {
      showgames: false,
    };
  },
  props: {
    isTips: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapGetters('betsGeneral', ['getParlayFreeBreakdown', 'getParlayFreeGain']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    isMobile() {
      return this.displayWidth < 680;
    },
  },
  watch: {},
  methods: {
    clickShowFreeSection() {
      this.$emit('changeView', 'free');
    },
    onShowClick() {
      this.$emit('show');
    },
    clickShowMoreGames() {
      this.showgames = !this.showgames;
      if (this.showgames) {
        parlayBreakdownSeeMore({
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
        });
      } else {
        parlayBreakdownSeeLess({
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
        });
      }
    },
    sendAnalyticsStrategy() {
      parlayValueBetting({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
    },
    sendAnalyticsBreakdown() {
      parlayBreakdown({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.button-making-money {
  width: 406px;
  height: 74px;
  border-radius: 7px;
  background-color: #132839;
  display: inline-flex;
  justify-content: center;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  @media screen and (max-width: 490px) {
    width: 270px;
    height: 64px;
  }
  &__icon {
    width: 29px;
    height: 18px;
    margin-top: 21px;
    margin-left: 4px;
    @media screen and (max-width: 490px) {
      margin-top: 19px;
    }
    img {
      width: 29px;
      @media screen and (max-width: 490px) {
        width: 24px;
      }
    }
  }
  &__text {
    font-family: 'Heebo';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 74px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    padding-right: 5px;
    @media screen and (max-width: 490px) {
      font-size: 12px;
      line-height: 64px;
    }
  }
}
.line-separator {
  border-top: dashed 1px #94a8ba;
  margin: 0;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
}
.pink-color {
  color: #ee6b83 !important;
}
.parlay-desglose {
  width: 87.16%;
  height: auto;
  margin: 0 auto;
  padding-bottom: 60px;
  @media screen and (max-width: 880px) {
    width: 95.16%;
  }
  @media screen and (max-width: 730px) {
    width: 100%;
  }
  &__contclose {
    width: 100%;
    height: 50px;

    &__item {
      width: 118px;
      height: 50px;
      float: right;
      display: inline-flex;
      justify-content: space-between;
      @media screen and (max-width: 680px) {
        margin-right: 3%;
      }
      &__text {
        width: 88px;
        height: 50px;
        font-family: 'Roboto-Regular';
        font-size: 23px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 50px;
        letter-spacing: -0.36px;
        text-align: center;
        color: #313a42;
        @media screen and (max-width: 680px) {
          font-size: 18px;
          text-align: right;
          padding-right: 8px;
          line-height: 48px !important;
        }
      }
      &__icon {
        width: 30px;
        height: 50px;
        img {
          margin-top: 10px;
        }
      }
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }
  &__infocont {
    width: 100%;
    height: auto;
    border-radius: 24px;
    border: solid 1px #d3d9de;
    margin: 0 auto;
    @media screen and (max-width: 480px) {
      width: 96%;
    }
    &__contbuttontips {
      width: 100%;
      height: 74px;
      margin-top: 24px;
      @media screen and (max-width: 490px) {
        height: 64px;
      }
    }
    &__contheader {
      width: 100%;
      height: 60px;
      margin-top: 34px;
      margin-bottom: 26px;
      display: inline-flex;
      justify-content: center;
      @media screen and (max-width: 680px) {
        margin-top: 14px;
        margin-bottom: 0px;
        height: 30px;
      }
      &__header {
        width: 100%;
        height: 60px;
        font-family: 'Roboto-Black';
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 60px;
        letter-spacing: -0.56px;
        text-align: center;
        color: #313a42;
        @media screen and (max-width: 980px) {
          font-size: 26px;
        }
        @media screen and (max-width: 780px) {
          font-size: 24px;
        }
        @media screen and (max-width: 680px) {
          font-size: 20px;
          width: 100%;
          line-height: 30px;
        }
        @media screen and (max-width: 480px) {
          font-size: 18px;
        }
        @media screen and (max-width: 380px) {
          font-size: 16px;
        }
      }
    }
    &__contshowmore {
      width: 834px;
      height: 44px;
      margin-top: 56px;
      position: relative;
      display: inline-flex;
      justify-content: center;
      @media screen and (max-width: 980px) {
        width: 703px;
      }

      @media screen and (max-width: 780px) {
        width: 653px;
      }
      @media screen and (max-width: 680px) {
        width: 94%;
      }
      &__button {
        width: 241px;
        height: 44px;
        background: white;
        margin: 0 auto;
        position: absolute;
        &__cont {
          width: 221px;
          height: 44px;
          background: white;
          margin: 0 auto;
          border-radius: 8px;
          border: solid 1px #c6d3df;
          display: inline-flex;
          justify-content: center;
          &__title {
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 44px;
            letter-spacing: normal;
            text-align: center;
            color: #505a64;
            padding-right: 14px;
          }
          &__image {
            margin-top: 10px;
          }
          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }
    }
    &__conttotal {
      width: 100%;
      height: 144px;
      margin-top: 56px;
      margin-bottom: 72px;
      display: inline-flex;
      justify-content: center;
      &__total-item {
        width: 834px;
        height: 144px;
        border-radius: 24px;
        border: solid 4px #cbee6b;
        @media screen and (max-width: 980px) {
          width: 703px;
        }
        @media screen and (max-width: 780px) {
          width: 653px;
        }
        @media screen and (max-width: 680px) {
          width: 94%;
        }
        &__title {
          width: 97%;
          height: 34px;
          border-radius: 7px;
          background-color: #132839;
          margin: 0 auto;
          margin-top: 20px;
          line-height: 34px;
          font-family: 'Roboto-Medium';
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
        }
        &__money {
          width: 97%;
          height: 46px;
          border-radius: 12px;
          background-color: #cbee6b;
          margin: 0 auto;
          margin-top: 16px;
          line-height: 46px;
          font-family: 'Roboto-Black';
          font-size: 28px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.56px;
          text-align: center;
          color: #132839;
        }
      }
    }
  }
}
.item-game {
  width: 834px;
  height: 122px;
  margin: 0 auto;
  margin-top: 24px;
  border: solid 1px #b1c2d0;
  background: white;
  border-radius: 16px;
  display: inline-flex;
  @media screen and (max-width: 980px) {
    width: 703px;
  }
  @media screen and (max-width: 780px) {
    width: 653px;
  }
  @media screen and (max-width: 680px) {
    width: 94%;
    height: 370px;
    display: inline-block;
  }

  &__matchday {
    width: 75px;
    height: 96px;
    margin-left: 16px;
    margin-top: 12px;
    border-radius: 12px;
    background-color: #132839;
    font-family: 'Roboto-Medium';
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 96px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    @media screen and (max-width: 980px) {
      margin-left: 8px;
      width: 65px;
    }
    @media screen and (max-width: 780px) {
      width: 60px;
    }
    @media screen and (max-width: 680px) {
      width: 97%;
      margin-left: 1.5%;
      height: 46px;
      line-height: 46px;
    }
  }
  &__contgames {
    width: 262px;
    height: 96px;
    margin-left: 12px;
    margin-top: 12px;
    display: inline-flex;
    @media screen and (max-width: 980px) {
      margin-left: 8px;
      width: 254px;
    }
    @media screen and (max-width: 780px) {
      width: 218px;
    }

    @media screen and (max-width: 680px) {
      width: 96%;
      margin: 0 auto;
      margin-top: 12px;
    }
    &__gameitem {
      width: 118px;
      height: 96px;
      @media screen and (max-width: 780px) {
        width: 100px;
      }
      @media screen and (max-width: 680px) {
        width: 45%;
      }
      &__team {
        width: 100%;
        height: 62px;
        border-radius: 12px;
        background-color: #0f92ff;
        display: inline-flex;
        justify-content: center;
        &__shield {
          width: 50px;
          height: 50px;
          margin-top: 6px;
        }
        &__name {
          width: 50px;
          height: 50px;
          margin-top: 6px;
          line-height: 50px;
          font-family: 'Roboto-Bold';
          font-size: 18px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          padding-left: 4px;
        }
      }
      &__bet {
        width: 100%;
        height: 30px;
        margin-top: 4px;
        border-radius: 8px;
        background-color: #cbee6b;
        font-family: 'Roboto-Bold';
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 30px;
        letter-spacing: normal;
        text-align: center;
        color: #000;
      }
    }
    &__more {
      width: 26px;
      height: 96px;
      font-family: 'Roboto-Regular';
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 96px;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      @media screen and (max-width: 980px) {
        width: 18px;
      }

      @media screen and (max-width: 680px) {
        width: 10%;
      }
    }
  }
  &__betinfo {
    width: 160px;
    height: 96px;
    border-radius: 12px;
    background-color: #132839;
    margin-left: 12px;
    margin-top: 12px;
    @media screen and (max-width: 980px) {
      margin-left: 8px;
      width: 135px;
    }
    @media screen and (max-width: 780px) {
      width: 126px;
    }
    @media screen and (max-width: 680px) {
      width: 97%;
      margin: 0 auto;
      height: 51px;
      line-height: 51px;
      margin-top: 14px;
      display: inline-flex;
      justify-content: center;
    }
    &__title {
      width: 100%;
      height: 20px;
      margin-top: 25px;
      font-family: 'Roboto-Regular';
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 20px;
      letter-spacing: normal;
      text-align: center;
      opacity: 0.6;
      color: #fff;
      @media screen and (max-width: 680px) {
        margin-top: 0px;
        width: auto;
        line-height: 51px;
        padding-right: 8px;
      }
    }
    &__money {
      width: 100%;
      height: 26px;
      font-family: 'Roboto-Bold';
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 26px;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      @media screen and (max-width: 680px) {
        width: auto;
        line-height: 51px;
      }
    }
  }
  &__equal {
    width: 26px;
    height: 96px;
    text-align: center;
    line-height: 96px;
    font-size: 24px;
    font-family: 'Roboto-Regular';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-top: 12px;
    letter-spacing: normal;
    color: #000;
    @media screen and (max-width: 980px) {
      width: 18px;
    }
    @media screen and (max-width: 680px) {
      width: 97%;
      margin: 0 auto;
      height: 30px;
      line-height: 30px;
      margin-top: 0px;
    }
  }
  &__contgain {
    width: 255px;
    height: 96px;
    margin-top: 12px;
    border-radius: 12px;
    background-color: #132839;
    @media screen and (max-width: 980px) {
      width: 196px;
    }
    @media screen and (max-width: 680px) {
      width: 97%;
      margin: 0 auto;
      height: 96px;
      line-height: 60px;
      margin-top: 0px;
      display: inline-block;
    }
    &__payout {
      width: 100%;
      height: 17px;
      margin-top: 15px;

      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #8d98a1;
      &__title {
        font-family: 'Roboto-Regular';
      }
      &__money {
        font-family: 'Roboto-Bold';
        font-size: 14px;
      }
      @media screen and (max-width: 680px) {
        margin-top: 15px;
      }
    }

    &__money {
      height: 28px;
      width: 100%;
      margin: 0 auto;
      margin-top: 14px;
      font-family: 'Roboto-Black';
      font-size: 32px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: normal;
      text-align: left;
      color: #cbee6b;
      display: inline-flex;
      justify-content: center;
      @media screen and (max-width: 980px) {
        font-size: 26px;
      }
      @media screen and (max-width: 680px) {
        width: auto;
        margin: 0 0;
        margin-top: 15px;
        font-size: 32px;
        line-height: 30px;
        margin-left: 10px;
      }
      &__min {
        font-size: 16px !important;
        line-height: 36px;
        @media screen and (max-width: 980px) {
          line-height: 32px;
        }
        @media screen and (max-width: 680px) {
          line-height: 40px;
        }
      }
      &__type {
        font-family: 'Roboto-Regular';
      }
      &__images {
        height: 24px;
        width: 24px;
        margin-top: 0px;
        margin-right: 8px;
        @media screen and (max-width: 680px) {
          margin: 0 0;
          margin-top: 1px;
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
